import React, { Component } from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";

import TributeLabLogo from "../../static/img/investments/tribute-labs.png";
import NoiseLogo from "../../static/img/investments/noise.png";
import SpearbitLogo from "../../static/img/investments/spearbit.png";
import NeonLogo from "../../static/img/investments/neon.png";
import ReadyPlayerLogo from "../../static/img/investments/ready-player.png";
import TempusLogo from "../../static/img/investments/tempus.svg";
import ConsensysLogo from "../../static/img/investments/consensys.svg";
import FlamingoLogo from "../../static/img/investments/flamingo.svg";
import GlimpseLogo from "../../static/img/investments/glimpse.svg";
import LAOLogo from "../../static/img/investments/lao.svg";
import MapleLogo from "../../static/img/investments/maple.svg";
import MonkLogo from "../../static/img/investments/monk.svg";
import NFTOasisLogo from "../../static/img/investments/nft-oasis.png";
import NeptuneLogo from "../../static/img/investments/neptune.png";
import PrePOLogo from "../../static/img/investments/prepo.svg";
import SonarLogo from "../../static/img/investments/sonar.svg";
import TalismanLogo from "../../static/img/investments/talisman.svg";
import ThalesLogo from "../../static/img/investments/thales.png";
import TransakLogo from "../../static/img/investments/transak.svg";
import FabricantLogo from "../../static/img/investments/fabricant.svg";
import ChainlinkLogo from "../../static/img/investments/chainlink.svg";
import SpaceAndTimeLogo from "../../static/img/investments/space-and-time.svg";
import SpaceshipLogo from "../../static/img/investments/spaceship.svg";
import MoleculeLogo from "../../static/img/investments/molecule.svg";

class Portfolio extends Component {
  render() {
    return (
      <>
        <Layout>
          <style type="text/css">{`
            body {
              background: #000;
            }
            ::-webkit-scrollbar-track {
              background: rgba(0, 0, 0, 1);
            }
          `}</style>
          <SEO title="Portfolio" />
          <section className="normal-section investments light-nav contrast-nav">
            <div className="container">
              <span className="subheading">PORTFOLIO</span>
              <a
                href="https://www.spaceandtime.io/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={SpaceAndTimeLogo} alt={"Spearbit Logo"} />
                </picture>
                <p>
                  <b>Space and Time</b>
                </p>
                <p>The decentralised data analytics warehouse.</p>
              </a>
              <a
                href="https://spearbit.com"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={SpearbitLogo} alt={"Spearbit Logo"} />
                </picture>
                <p>
                  <b>Spearbit</b>
                </p>
                <p>
                  Spearbit connects security experts to the Web3 projects that
                  need them.
                </p>
              </a>
              <a
                href="https://www.maple.finance/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={MapleLogo} alt={"Maple Logo"} />
                </picture>
                <p>
                  <b>Maple</b>
                </p>
                <p>
                  Maple is a decentralised corporate credit market built on the
                  Ethereum blockchain.
                </p>
              </a>
              <a
                href="https://chain.link"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={ChainlinkLogo} alt={"Chainlink Logo"} />
                </picture>
                <p>
                  <b>Chainlink</b>
                </p>
                <p>Solving the Oracle problem.</p>
              </a>
              <a
                href="https://consensys.net/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={ConsensysLogo} alt={"Consensys Logo"} />
                </picture>
                <p>
                  <b>Consensys</b>
                </p>
                <p>
                  Consensys is a market-leading Ethereum-focused software
                  company.
                </p>
              </a>
              <a
                href="https://tributelabs.xyz"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={TributeLabLogo} alt={"Tribute Lab Logo"} />
                </picture>
                <p>
                  <b>Tribute Labs</b>
                </p>
                <p>
                  Helping build DAO communities through open‐source frameworks
                  built on Ethereum.
                </p>
              </a>
              <a
                href="https://talisman.xyz/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={TalismanLogo} alt={"Talisman Logo"} />
                </picture>
                <p>
                  <b>Talisman</b>
                </p>
                <p>
                  Talisman is a community-owned wallet built for the Paraverse.
                </p>
              </a>
              <a
                href="https://flamingodao.xyz/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={FlamingoLogo} alt={"Flamingo Logo"} />
                </picture>
                <p>
                  <b>Flamingo</b>
                </p>
                <p>
                  Flamingo is a DAO, incubated by the LAO community supporting
                  the expanding NFT metaverse.
                </p>
              </a>
              <a
                href="https://readyplayerdao.xyz"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={ReadyPlayerLogo} alt={"Ready Player DAO Logo"} />
                </picture>
                <p>
                  <b>Ready Player DAO</b>
                </p>
                <p>
                  P2E in the decentralised and permissionless future of gaming.
                </p>
              </a>
              <a
                href="https://www.thefabricant.com/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={FabricantLogo} alt={"Fabricant Logo"} />
                </picture>
                <p>
                  <b>Fabricant</b>
                </p>
                <p>The home of digital fashion.</p>
              </a>
              <a
                href="https://glimpseprotocol.io/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={GlimpseLogo} alt={"GlimpseLogo"} />
                </picture>
                <p>
                  <b>Glimpse Protocol</b>
                </p>
                <p>
                  Glimpse Protocol is a privacy-preserving ad platform for the
                  post-cookie web.
                </p>
              </a>
              <a
                href="https://molecule.to/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={MoleculeLogo} alt={"Molecule Logo"} />
                </picture>
                <p>
                  <b>Molecule</b>
                </p>
                <p>
                  A decentralised biotech protocol porting IP into web3 and
                  launching biotech DAOs.
                </p>
              </a>
              <a
                href="https://monk.io/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={MonkLogo} alt={"Monk Logo"} />
                </picture>
                <p>
                  <b>Monk</b>
                </p>
                <p>
                  Monk is the stack orchestration platform allowing anyone to
                  compose, deploy and manage full-stacks with near-zero devops
                  overhead.
                </p>
              </a>
              <a
                href="https://neondao.xyz/join"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={NeonLogo} alt={"Neon DAO Logo"} />
                </picture>
                <p>
                  <b>Neon DAO</b>
                </p>
                <p>A DAO for the Metaverse.</p>
              </a>
              <a
                href="https://neptunedao.xyz/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={NeptuneLogo} alt={"Neptune Logo"} />
                </picture>
                <p>
                  <b>Neptune</b>
                </p>
                <p>
                  Neptune is a DAO, incubated by the LAO community supporting
                  the provision of early stage liquidity.
                </p>
              </a>
              <a
                href="https://www.nftoasis.co/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={NFTOasisLogo} alt={"NFT Oasis Logo"} />
                </picture>
                <p>
                  <b>NFT Oasis</b>
                </p>
                <p>
                  NFT Oasis is a fully immersive VR Metaverse platform
                  spearheading the future of online social experiences.
                </p>
              </a>
              <a
                href="https://noisedao.xyz"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={NoiseLogo} alt={"Noise DAO Logo"} />
                </picture>
                <p>
                  <b>Noise DAO</b>
                </p>
                <p>A DAO for sound and music NFTs.</p>
              </a>
              <a
                href="https://prepo.io/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={PrePOLogo} alt={"prePo Logo"} />
                </picture>
                <p>
                  <b>prePO</b>
                </p>
                <p>
                  prePO is a decentralized trading platform allowing anyone to
                  speculate on the value of any pre‑IPO company or pre‑token
                  project.
                </p>
              </a>
              <a
                href="https://sonar.watch/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={SonarLogo} alt={"Sonar Logo"} />
                </picture>
                <p>
                  <b>Sonar</b>
                </p>
                <p>
                  Sonar is the solution bringing transactions, assets, and data
                  onto a unique user dashboard.
                </p>
              </a>
              <a
                href="https://spaceshipdao.xyz/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={SpaceshipLogo} alt={"Spaceship DAO Logo"} />
                </picture>
                <p>
                  <b>SpaceshipDAO</b>
                </p>
                <p>
                  Spaceship is a global group of crypto natives, founders,
                  builders, and investors.
                </p>
              </a>
              <a
                href="https://tempus.finance"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={TempusLogo} alt={"Tempus Logo"} />
                </picture>
                <p>
                  <b>Tempus</b>
                </p>
                <p>
                  Tempus is building the most important blocks of DeFi and Web3
                  in a way that's scalable and accessible to all.
                </p>
              </a>
              <a
                href="https://thalesmarket.io"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture className="bordered">
                  <img src={ThalesLogo} alt={"Thales Logo"} />
                </picture>
                <p>
                  <b>Thales</b>
                </p>
                <p>
                  Thales is a binary options platform, allowing users to
                  permissionlessly deploy new markets for hedging and
                  speculating on synthetic assets.
                </p>
              </a>
              <a
                href="https://www.thelao.io/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={LAOLogo} alt={"The LAO Logo"} />
                </picture>
                <p>
                  <b>The LAO</b>
                </p>
                <p>
                  'The LAO' is a for-profit Decentralised Autonomous
                  Organisation (DAO) supporting the development of the Ethereum
                  ecosystem.
                </p>
              </a>
              <a
                href="https://transak.com/"
                rel="noreferrer"
                target="_blank"
                className="investment"
              >
                <picture>
                  <img src={TransakLogo} alt={"Transak Logo"} />
                </picture>
                <p>
                  <b>Transak</b>
                </p>
                <p>Transak is your fiat gateway to DeFi.</p>
              </a>
            </div>
          </section>
        </Layout>
      </>
    );
  }
}

export default Portfolio;
